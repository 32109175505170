import React from "react";

import ContentBlocks from "../components/ContentBlocks/ContentBlocks";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";

const HubDetail = ({ pageContext }) => {
  const {
    meta_title,
    meta_description,
    title,
    date,
    menu,
    lang = "es",
    footer,
    blocks,
    cookie_message_button_text,
    cookie_message_text
  } = pageContext;

  const lastBlockName =
    blocks && blocks.length > 0
      ? blocks[blocks.length - 1].slice_type
        ? blocks[blocks.length - 1].slice_type.charAt(0).toUpperCase() +
          blocks[blocks.length - 1].slice_type.slice(1)
        : null
      : null;

  const LANGS_MAP = {
    en: "en-US",
    es: "es-ES"
  };
  const d = new Date(date).toLocaleDateString(LANGS_MAP[lang], {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });

  return (
    <Layout
      className={`HubDetail ${
        lastBlockName === "Call_to_action" ? "without-footer-decorator" : ""
      }`}
      lang={lang}
      menu={menu}
      footer={footer}
      cookie_message_text={cookie_message_text}
      cookie_message_button_text={cookie_message_button_text}
    >
      <SEO title={meta_title} description={meta_description} />

      <div className="HubDetail-title-container container">
        <div className="container-md">
          <div className="HubDetail-title-container-wrapper">
            {title && title.text && <h1>{title.text}</h1>}
            <p className="HubDetail-talk-date">{d}</p>
          </div>
        </div>
      </div>

      {blocks.map((block, index) => {
        if (!block || !block.slice_type) return;

        const { slice_type } = block;
        const componentName = slice_type
          ? slice_type.charAt(0).toUpperCase() + slice_type.slice(1)
          : null;
        return (
          <ContentBlocks
            tag={componentName}
            key={slice_type + index}
            {...block}
          />
        );
      })}
    </Layout>
  );
};

export default HubDetail;
